import React from 'react'
import styled, { keyframes } from 'styled-components'
import { navigate } from 'gatsby'

import { P } from '../shared/text'

const animateArrow = keyframes`
  0% { transform: translate(0, 0) }
  50% { transform: translate(3px, -3px)}
  100% { transform: translate(0, 0) }
`

const StyledLink = styled.div`
  width: fit-content;
  display: grid;
  align-items: end;
  grid-template-columns: auto 20px;
  color: inherit;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  transition: 200ms all ease-in-out;
  cursor: pointer;

  & svg {
    height: 26px;
    width: 30px;
    transition: 300ms all ease-in-out;
    margin-bottom: 4px;

    & path {
      height: 24px;
      width: 26px;
      transition: 300ms all ease-in-out;
      fill: ${props => props.theme.colors.azure} !important;
    }
  }

  &:hover {
    color: ${props => props.theme.colors.healerite};

    & svg {
      animation: ${animateArrow} 1s ease infinite;

      & path {
        fill: ${props => props.theme.colors.healerite} !important;
      }
    }
  }
`

const LinkArrow = ({ copy, destination, external }) => {
  const Contents = () => (
    <>
      <P>{copy}</P>

      <svg
        width="39"
        height="39"
        viewBox="0 0 39 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            width="27"
            height="27"
            d="M9.70309 26.4995C9.76854 26.4087 9.84175 26.3238 9.92187 26.2457L23.3908 12.7768L22.9605 12.9339C22.5376 13.0851 22.0915 13.1613 21.6423 13.1593L14.0883 13.1593C13.116 13.1819 12.2685 12.5015 12.0802 11.5474C11.9172 10.493 12.6398 9.50609 13.6942 9.34307C13.7795 9.32992 13.8655 9.32245 13.9517 9.32078L27.6118 9.32078C28.6787 9.32018 29.5441 10.1846 29.5447 11.2516C29.5447 11.2522 29.5447 11.253 29.5447 11.2537L29.5447 24.9138C29.5432 25.9807 28.6771 26.8444 27.6102 26.843C27.5262 26.8429 27.4423 26.8373 27.3591 26.8263C26.4049 26.638 25.7246 25.7905 25.7472 24.8182L25.7335 17.2642C25.7333 16.8631 25.7955 16.4644 25.9179 16.0826L26.1433 15.4883L12.7291 28.9026C12.0572 29.6263 10.9575 29.7511 10.1405 29.1963C9.27502 28.5724 9.07921 27.365 9.70309 26.4995Z"
            fill="#1325A9"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="27"
              height="27"
              fill="white"
              transform="matrix(-0.707107 0.707107 0.707107 0.707107 19.4414 0.349487)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  )

  return external ? (
    <StyledLink
      as="a"
      href={destination}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Contents />
    </StyledLink>
  ) : (
    <StyledLink onClick={() => navigate(destination)}>
      <Contents />
    </StyledLink>
  )
}

export default LinkArrow
