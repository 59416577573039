import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import Carousel from './Carousel'
import LinkArrow from '../../shared/LinkArrow'
import { H2, P } from '../../shared/text'
import { media } from '../../utils/style-utils'

import cursorDesktop from '../../../img/homepage/learn-more-cursor.png'
import cusrorViewAll from '../../../img/homepage/view-all-cursor.png'
const Container = styled.div`
  width: 100%;
  margin-bottom: 50px;
`

const Header = styled.div`
  position: relative;

  width: 100%;
  margin: 0 auto;
  color: ${props => props.theme.colors.azure};

  ${media.small`
    padding: 65px 25px 40px 25px;
  `}
  ${media.medium`
    padding: 65px 80px 40px 80px;
  `}
  ${media.large`
    max-width: 1268px;
    padding: 84px 100px 53px 100px;
  `}

  & > h2,
  > p {
    visibility: hidden;
  }

  & > h2 {
    margin-bottom: 20px;
  }
`

const IndustryPill = styled.div`
  opacity: ${props => (props.active ? 1 : 0)};

  transform: rotate(-90deg) translate(-100%, 0);
  transform-origin: top left;

  position: absolute;
  top: 12px;
  left: 12px;

  border: 1px solid ${props => props.theme.colors.azure};
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.azure};

  text-align: center;
  border-radius: 50px;
  font-size: 20px;
  line-height: 1;
  transition: 300ms all ease-in-out;

  padding: 0 11px;
  height: 37px;
`

const ImageWrapper = styled.div`
  position: relative;

  background-color: ${props => props.theme.colors.gandalf};
  cursor: url(${props => props.viewAll ? cusrorViewAll : cursorDesktop}) 45 45, auto;

  width: 100%;

  ${media.small`
    height: 300px;
  `}
  ${media.medium`
    height: 400px;
  `}
  ${media.large`
    height: 553px;
  `}
  
  & img {
    transition: 500ms transform ease;
  }

  &:hover img {
    transform: scale(1.008);
  }
`

const LinkArrowWrapper = styled.div`
  position: absolute;

  ${media.small`
    top: 25px;
    right: 25px;
  `}
  ${media.large`
    top: 65px;
    right: 55px;
  `}
`

const LogoWrapper = styled.div`
  position: absolute;

  ${media.small`
    width: 45px;
    height: 45px;
    top: 12px;
    right: 12px;
  `}
  ${media.medium`
    width: 76px;
    height: 76px;
    top: 32px;
    right: 24px;
  `}
`

const PortfolioTile = ({id, images, title, description, url, linkText}) => {

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.registerPlugin(ScrollToPlugin)

    const tl = gsap.timeline({
      defaults: { opacity: 0, duration: 0.5, y: 20 },
      scrollTrigger: `#${id}`,
    })
    tl.to(
      `#${id} .portfolio__title, 
       #${id} .portfolio__body, 
       #${id} .portfolio__carousel-image, 
       #${id} .portfolio__learn-more-link`,
      {
        visibility: 'visible',
      }
    )
    tl.from(`#${id} .portfolio__title`, {})
    tl.from(`#${id} .portfolio__learn-more-link`, { duration: 1.5, x: -20 }, '<')
    tl.from(
      `#${id} .portfolio__carousel-image`,
      {
        stagger: 0.25,
        duration: 1,
        y: 0,
        ease: 'linear',
      },
      '<'
    )
    tl.from(`#${id} .portfolio__body`, {}, '<0.15')
  }, [])

  const handleClick = id => {
    navigate(url)
    if (typeof window !== 'undefined') {
      gsap.to(window, {
        delay: 1.5,
        duration: 0.2,
        scrollTo: { y: id },
      })
    }
  }

  return (
    <Container id={id}>
      <Header>
        <H2 className="portfolio__title">{title}</H2>
        <P className="portfolio__body">{description}</P>

        <LinkArrowWrapper className="portfolio__learn-more-link">
          <LinkArrow copy={linkText} destination={url} />
        </LinkArrowWrapper>
      </Header>
      <Carousel>
        {images.map(image => (
          <ImageWrapper
            onClick={() => handleClick(image.id)}
            key={image.alt}
            viewAll={linkText === 'view all'}
            className="portfolio__carousel-image"
          >
            <Img
              fluid={image.fluid}
              alt={image.alt}
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
              backgroundColor="#F5F5F5"
            />
            <LogoWrapper>
              <Img
                fluid={image.logoFluid}
                alt={`${image.alt} logo`}
                style={{ height: '100%', width: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </LogoWrapper>
            <IndustryPill className="portfolio__industry-pill">
              <P>{image.industry.toLowerCase()}</P>
            </IndustryPill>
          </ImageWrapper>
        ))}
      </Carousel>
    </Container>
  )
}

export default PortfolioTile
