import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/shared/SEO'
import Layout from '../components/Layout'
import Homepage from '../components/Homepage'

const IndexPage = ({ location, data }) => {

  return (
    <>
      <SEO title="Bringing Bright Ideas to Light" />
      <Layout location={location}>
        <Homepage employeeCount={31} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query IndexPageQuery {
    allMarkdownRemark(
      filter: {
        frontmatter: { employees: { elemMatch: { hidden: { eq: false } } } }
      }
    ) {
      edges {
        node {
          frontmatter {
            employees {
              email
            }
          }
        }
      }
    }
  }
`

export default IndexPage
