import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useEmblaCarousel } from 'embla-carousel/react'

import { media } from '../../utils/style-utils'

import { useRecursiveTimeout } from '../../../hooks/useRecursiveTimeout'

const EmblaCarousel = styled.div`
  position: relative;
`

const ViewPort = styled.div`
  overflow: hidden;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;

  ${media.small`
    margin-left: -10px;
  `}
  ${media.medium`
    margin-left: -20px;
  `}
`

const Slide = styled.div`
  position: relative;

  ${media.small`
    min-width: 85%;
    padding-left: 10px;
  `}
  ${media.large`
    min-width: 40%;
    padding-left: 20px;
  `}
`

const SlideInner = styled.div`
  position: relative;
  overflow: hidden;

  ${media.small`
    height: 300px;
  `}
  ${media.large`
    height: 553px;
  `}

  & > div:first-of-type {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: auto;
    min-height: 100%;
    min-width: 100%;
    max-width: none;
    transform: translate(-50%, -50%);

    & img {
      filter: ${props => (props.active ? 'grayscale(0)' : 'grayscale(100%)')};
    }
  }

  .portfolio__industry-pill {
    opacity: ${props => (props.active ? 1 : 0)};
  }
`

const Carousel = ({ children }) => {
  const [activeIdx, setActiveIdx] = useState(1)
  const [viewportRef, embla] = useEmblaCarousel({
    loop: true,
    align: 'center',
    startIndex: 1,
  })

  const childCount = children.length

  const autoplay = useCallback(() => {
    if (!embla) return

    setActiveIdx(prevState => {
      const nextIdx = prevState + 1
      return nextIdx < childCount ? nextIdx : 0
    })

    if (embla.canScrollNext()) embla.scrollNext()
    else embla.scrollTo(0)
  }, [embla, childCount])

  const AUTOPLAY_INTERVAL = 4000
  const { play } = useRecursiveTimeout(autoplay, AUTOPLAY_INTERVAL)

  useEffect(() => {
    play()
  }, [play])

  return (
    <EmblaCarousel>
      <ViewPort className="embla__viewport" ref={viewportRef}>
        <Container className="embla__container">
          {children.map((child, idx) => (
            <Slide key={`child-${idx}`}>
              <SlideInner active={activeIdx === idx}>{child}</SlideInner>
            </Slide>
          ))}
        </Container>
      </ViewPort>
    </EmblaCarousel>
  )
}

export default Carousel
