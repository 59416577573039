import React, { useEffect } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import LinkArrow from '../shared/LinkArrow'
import { H2, LargeP, P } from '../shared/text'
import { media } from '../utils/style-utils'

const Container = styled.div`
  background-color: ${props => props.theme.colors.gandalf};
  width: 100%;
  height: fit-content;
  display: grid;
  place-items: center;

  ${media.small`
    grid-row-gap: 68px;
    padding: 100px 24px 44px 24px;
  `}
  ${media.medium`
    padding: 157px 100px 79px 100px;
  `}
  ${media.large`
    grid-row-gap: 144px;
  `}

  .hidden {
    visibility: hidden;
  }
`

const TextGrid = styled.div`
  position: relative;
  overflow: visible;

  width: 100%;
  display: grid;
  text-align: left;
  color: ${props => props.theme.colors.azure};

  ${media.small`
    grid-gap: 60px 0;
  `}
  ${media.large`
    max-width: 1200px;
    grid-gap: 0 120px;
    grid-template-columns: repeat(2, 1fr);
  `}
`

const StatBoxGrid = styled(TextGrid)`
  ${media.small`
    grid-gap: 60px 0;
    grid-template-columns: repeat(2, 1fr);

    & p {
      font-size: 18px;
    }
  `}
  ${media.large`
    & p {
      font-size: 36px;
    }
  `}
`

const TextGroup = styled.div`
  width: 100%;
  display: grid;
  grid-row-gap: 26px;
`

const BlueLine = styled.div`
  height: 4px;
  background-color: ${props => props.theme.colors.azure};
  transform: translate(-50%, 0);
  position: absolute;
  top: 32%;
  left: 52%;
  border-radius: 100px;

  ${media.small`
    width: 25%;
  `}
  ${media.large`
    width: 45%;
  `}
`

const StatBox = styled.div`
  display: grid;
  place-items: center;
  white-space: pre-line;

  ${media.small`
    width: 125px;
  `}
  ${media.large`
    width: 245px;
  `}

  & > p {
    text-align: center;
    line-height: 1.2;
  }

  &:first-of-type {
    justify-self: start;
  }
  &:last-of-type {
    justify-self: end;
  }
`

const StatNumber = styled.div`
  font-family: Apercu, sans-serif;
  text-align: center;
  color: ${props => props.theme.colors.healerite};

  ${media.small`
    font-size: 100px;
    line-height: 100px;
  `}
  ${media.large`
    font-size: 200px;
    line-height: 180px;
  `}

  & * {
    width: 100%;
  }
`

const JoinUsContainer = styled.div`
  visibility: hidden;

  position: absolute;

  ${media.small`
    top: -60px;
    right: 0;
  `}
  ${media.large`
    top: -80px;
    right: -40px;
  `}
`

const About = ({ employeeCount }) => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const tl = gsap.timeline({
      defaults: { opacity: 0, duration: 0.5, y: 20 },
      scrollTrigger: '.text-grid__title',
    })
    tl.to('.text-grid__title, .text-grid__body, .join-us-link', {
      visibility: 'visible',
    })
    tl.from('.text-grid__title', {})
    tl.from('.text-grid__body', {}, '<0.15')
    tl.from('.join-us-link', { duration: 1.5, x: -20 })

    const formattedNumber = n => (n >= 10 ? n : `0${n}`)

    const countUpConfig = {
      ease: 'none',
      innerText: '00',
      roundProps: 'innerText',
      onUpdate: function() {
        this.targets().forEach(target => {
          const val = gsap.getProperty(target, 'innerText')
          target.innerText = formattedNumber(val)
        })
      },
    }

    const tl2 = gsap.timeline({ scrollTrigger: '.stat-line', delay: 0.25 })
    tl2.from('.stat-figure--left', { ...countUpConfig, duration: 1.5 })
    tl2.from('.stat-figure--right', { ...countUpConfig, duration: 2.5 }, '<')
    tl2.from('.stat-box', { opacity: 0, duration: 3 }, '<')
    tl2.from('.stat-line', { width: 0, duration: 2 }, '<')
  }, [])

  return (
    <Container>
      <TextGrid>
        <TextGroup>
          <H2 className="text-grid__title hidden">Our Philosophy</H2>
          <P className="text-grid__body hidden">
            With our platform-based company-building approach, each win unlocks
            the next through unique learnings, reallocation of capital, and
            increased expertise amongst our team. All in all, we've created a
            process that maximizes our potential for success with each new
            build.
          </P>
        </TextGroup>
        <TextGroup>
          <H2 className="text-grid__title hidden">Our Values</H2>
          <P className="text-grid__body hidden">
            We firmly believe that a company’s core values are the foundation
            and heart of its business. At Innovation Department, we champion
            taking ownership, staying transparent, having grit, being adaptable,
            actively collaborating, and letting results do the talking.
          </P>
        </TextGroup>

        <JoinUsContainer className="join-us-link">
          <LinkArrow copy="join the team" destination="/careers" />
        </JoinUsContainer>
      </TextGrid>

      <StatBoxGrid className="stat-box">
        <StatBox>
          <StatNumber className="stat-figure--left">
            <span>5</span>
          </StatNumber>
          <LargeP>{`Companies\nbuilt`}</LargeP>
        </StatBox>

        <BlueLine className="stat-line" />

        <StatBox>
          <StatNumber className="stat-figure--right">
            <span>{employeeCount}</span>
          </StatNumber>
          <LargeP>{`Investments`}</LargeP>
        </StatBox>
      </StatBoxGrid>
    </Container>
  )
}

export default About
