import React, { useEffect } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'

import { media } from '../utils/style-utils'

const ColoredContainer = styled.div`
  visibility: hidden;
  overflow: hidden;

  height: 50px;

  margin: auto;
  // width: calc(100% + ${props => props.childWidth * 2});
  width: 100%;

  background-color: ${props => props.bgColor};

  & p {
    font-size: 24px !important;
  }
`

const GridGroup = styled.div`
  width: fit-content;
  height: 50px;
  // left: -${props => props.childWidth * 2}px;

  display: grid;
  align-items: center;
  margin: 0 auto;

  ${media.medium`
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 30px;
  `}
  ${media.large`
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 0;
  `}
`

const ChildWrapper = styled.div`
  display: none;

  ${media.small`
    &:first-of-type {
      display: block;
    }
  `}
  ${media.medium`
    &:nth-of-type(2) {
      display: block;
    }
  `}
  ${media.large`
    display: block;
  `}
`

const Marquee = ({ children, bgColor, childWidth, revealAnimation }) => {
  useEffect(() => {
    //initially position children instances in a row
    // gsap.set('.marquee__children-wrapper', { x: i => i * childWidth })

    if (revealAnimation) {
      gsap.to('.marquee__colored-container', { visibility: 'visible' })

      gsap.from('.marquee__colored-container', {
        y: -50,
        opacity: 0,
        ease: 'power4.out',
        duration: 0.8,
        delay: 1.5,
      })
    }

    // const tl = gsap.timeline({ delay: 0.75, repeat: -1 })

    // tl.to('.marquee__children-wrapper', {
    //   duration: 5,
    //   ease: 'none',
    //   x: `+=${childWidth}`, // moves each ChildrenWrapper to right
    // })
  }, [childWidth, revealAnimation])

  return (
    <ColoredContainer
      bgColor={bgColor}
      childWidth={childWidth}
      className="marquee__colored-container"
    >
      <GridGroup className="marquee__grid-group" childWidth={childWidth}>
        {children.map((child, idx) => (
          <ChildWrapper
            className="marquee__children-wrapper"
            key={`child-${String(idx)}`}
          >
            {child}
          </ChildWrapper>
        ))}
      </GridGroup>
    </ColoredContainer>
  )
}

export default Marquee
