import React from 'react'

export default () => (
  <svg
    width="138"
    height="50"
    viewBox="0 0 138 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M135.54 46.516H136.101C136.343 46.516 136.614 46.6063 136.614 46.92C136.614 47.2765 136.276 47.305 135.972 47.305H135.54V47.4571H136.067L136.681 48.3983H136.875L136.267 47.4666C136.557 47.4191 136.79 47.2575 136.79 46.9295C136.79 46.5588 136.585 46.3687 136.124 46.3687H135.359V48.403H135.535V46.516H135.54Z"
      fill="#1325A9"
    />
    <path
      d="M135.988 45.6621C136.939 45.6621 137.709 46.4321 137.709 47.3828C137.709 48.3334 136.939 49.1034 135.988 49.1034C135.037 49.1034 134.267 48.3334 134.267 47.3828C134.267 46.4274 135.037 45.6621 135.988 45.6621L135.993 45.8142C135.104 45.8142 134.443 46.4939 134.443 47.3828C134.443 48.2526 135.113 48.9513 135.993 48.9513C136.872 48.9513 137.538 48.2479 137.538 47.378C137.538 46.4987 136.877 45.8142 135.997 45.8142L135.988 45.6621Z"
      fill="#1325A9"
    />
    <path
      d="M118.872 47.4322H110.759V0H118.872V18.5803L123.036 0H131.711L126.24 19.0318L132.005 47.4322H123.573L120.621 26.8841L118.872 30.4728V47.4322Z"
      fill="#1325A9"
    />
    <path
      d="M93.8511 39.4495H85.9703V26.9771H92.1827V19.0107H85.9703V7.40342H93.8464V0.00268555H77.405V47.4301H93.8464V39.4495H93.8511Z"
      fill="#1325A9"
    />
    <path
      d="M110.051 39.4495H102.522V26.9771H108.464V19.0107H102.522V7.40342H110.051V0.00268555H94.3325V47.4301H110.056V39.4495H110.051Z"
      fill="#1325A9"
    />
    <path
      d="M44.28 0.00415039L50.0837 47.4316H58.8724L61.0874 16.9398L63.9678 47.4316H71.5254L76.8727 0.00415039H70.1802L68.5309 26.6506L65.7075 0.00415039H57.0281L54.7656 26.2323L51.9897 0.00415039H44.28Z"
      fill="#1325A9"
    />
    <path
      d="M36.2422 37.3013C36.2422 39.1075 35.4246 40.5715 34.4169 40.5715H33.0052V7.40367H34.4169C35.4246 7.40367 36.2422 8.86765 36.2422 10.6739V37.3013ZM38.0484 0.00292969H24.6111V29.9671L24.6016 29.8911V47.4351H38.0484C41.4897 47.4351 44.2798 43.4092 44.2798 38.4373V9.00074C44.2798 4.02889 41.4897 0.00292969 38.0484 0.00292969Z"
      fill="#1325A9"
    />
    <path
      d="M10.1401 30.2942L12.1935 10.0361L14.2516 30.2942H10.1401ZM18.8432 0.00683594H6.56569L0.880859 47.4628H8.2198L9.07538 36.8537H14.1993L15.0549 47.4628H24.1383L18.8432 0.00683594Z"
      fill="#1325A9"
    />
  </svg>
)
