import React, { useRef, useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Marquee from '../shared/Marquee'
import AboveTheFold from './AboveTheFold'
import About from './About'
import PortfolioTile from './PortfolioTile'
import Press from './Press'

import IDLightbulbSVG from '../shared/image-components/IDLightbulbSVG'
import { P } from '../shared/text'
import { media } from '../utils/style-utils'

const Main = styled.div`
  width: 100%;
`

const LogoMarquee = styled.div`
  display: grid;
  place-items: center;

  color: ${props => props.theme.colors.azure};

  ${media.small`
    grid-template-columns: 50px 1fr;
  `}
  ${media.large`
    grid-template-columns: 100px 300px;
  `}

  & svg {
    height: 29px;
    width: 19.5px;
  }
`

const LightbulbWrapper = styled.div`
  height: 29px;
  width: 50px;
  display: grid;
  place-items: center;
`

const query = graphql`
  query {
    grummies: file(relativePath: { regex: "/portcos/grummies.jpeg/" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    grummiesLogo: file(
      relativePath: { regex: "/portcos/grummies-logo--azure.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    finn: file(relativePath: { regex: "/portcos/finn.jpeg/" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    finnLogo: file(relativePath: { regex: "/portcos/finn-logo--azure.png/" }) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dojomojo: file(relativePath: { regex: "/portcos/dojomojo.jpeg/" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dojomojoLogo: file(
      relativePath: { regex: "/portcos/dojomojo-logo--azure.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wellpath: file(relativePath: { regex: "/portcos/wellpath-model.jpeg/" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wellpathLogo: file(
      relativePath: { regex: "/portcos/wellpath-logo--azure.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dailyHarvest: file(relativePath: { regex: "/investments/daily-harvest.png/" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dailyHarvestLogo: file(
      relativePath: { regex: "/investments/daily-harvest-logo.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    caraway: file(relativePath: { regex: "/investments/caraway.png/" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carawayLogo: file(
      relativePath: { regex: "/investments/caraway-logo.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    seed: file(relativePath: { regex: "/investments/seed.png/" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    seedLogo: file(
      relativePath: { regex: "/investments/seed-logo.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pinterest: file(relativePath: { regex: "/investments/pinterest.png/" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pinterestLogo: file(
      relativePath: { regex: "/investments/pinterest-logo.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Homepage = ({ employeeCount }) => {

  const {
    grummies,
    finn,
    wellpath,
    dojomojo,
    grummiesLogo,
    finnLogo,
    wellpathLogo,
    dojomojoLogo,
    dailyHarvest,
    dailyHarvestLogo,
    caraway,
    carawayLogo,
    seed,
    seedLogo,
    pinterest,
    pinterestLogo
  } = useStaticQuery(query)

  const marqueeCount = [1, 2, 3]
  const [childWidth, setChildWidth] = useState(0)

  const marqueeRef = useRef(null)
  const element = marqueeRef ? marqueeRef.current : null

  useEffect(() => {
    if (element) {
      const elementHeight = element.getBoundingClientRect().width
      setChildWidth(elementHeight)
    }
  }, [element])

  const brand = [
    {
      fluid: dojomojo.childImageSharp.fluid,
      logoFluid: dojomojoLogo.childImageSharp.fluid,
      alt: 'DojoMojo',
      industry: 'SaaS, SMS Marketing, B2B',
      id: '#dojomojo',
    },
    {
      fluid: grummies.childImageSharp.fluid,
      logoFluid: grummiesLogo.childImageSharp.fluid,
      alt: 'Grummies',
      industry: 'health  & wellness',
      id: '#grummies',
    },
    {
      fluid: finn.childImageSharp.fluid,
      logoFluid: finnLogo.childImageSharp.fluid,
      alt: 'Finn',
      industry: 'pet wellness',
      id: '#finn',
    },
    {
      fluid: wellpath.childImageSharp.fluid,
      logoFluid: wellpathLogo.childImageSharp.fluid,
      alt: 'WellPath',
      industry: 'health  & wellness',
      id: '#wellpath',
    },
  ]

  const investments = [
    {
      fluid: dailyHarvest.childImageSharp.fluid,
      logoFluid: dailyHarvestLogo.childImageSharp.fluid,
      alt: 'Daily Harvest',
      industry: 'food & beverage',
      id: '#daily-harvest',
    },
    {
      fluid: caraway.childImageSharp.fluid,
      logoFluid: carawayLogo.childImageSharp.fluid,
      alt: 'Caraway',
      industry: 'consumer goods',
      id: '#caraway',
    },
    {
      fluid: seed.childImageSharp.fluid,
      logoFluid: seedLogo.childImageSharp.fluid,
      alt: 'Seed',
      industry: 'health & wellness',
      id: '#seed',
    },
    {
      fluid: pinterest.childImageSharp.fluid,
      logoFluid: pinterestLogo.childImageSharp.fluid,
      alt: 'pinterest',
      industry: 'internet',
      id: '#pinterest',
    },
  ]

  return (
    <Main>
      <Marquee
        bgColor={props => props.theme.colors.healerite}
        childWidth={Math.max(childWidth, 420)}
        revealAnimation
      >
        {marqueeCount.map((_, idx) => (
          <LogoMarquee key={`logo-marquee--${String(idx)}`} ref={marqueeRef}>
            <LightbulbWrapper>
              <IDLightbulbSVG />
            </LightbulbWrapper>
            <P>Bringing bright ideas to light</P>
          </LogoMarquee>
        ))}
      </Marquee>
      <AboveTheFold />
      <About employeeCount={employeeCount} />
      <PortfolioTile
        id="brand"
        images={brand} 
        url="/portfolio/built" 
        title="Our brand"
        linkText="learn more"
        description="Building companies is hard, but building exceptional companies is even harder. Get to know the companies we've built and developed from square one."
      />
      <PortfolioTile 
        id="investments"
        images={investments} 
        url="/portfolio/invested" 
        title="Our investments"
        linkText="view all" 
        description="We invest in the next generation of companies bringing positive change to the world."
      />
      <Press />
    </Main>
  )
}

export default Homepage
