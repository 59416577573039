import React from 'react'

export default () => (
  <svg
    width="138"
    height="32"
    viewBox="0 0 138 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.8136 3.83636C58.499 1.62125 55.3577 0.355469 52.051 0.355469H33.9472V32H46.3471V12.2222H50.315C51.3897 12.2222 52.4644 12.6177 53.2084 13.4088L57.507 17.5226C58.251 18.2346 58.747 19.2631 58.747 20.2915V32H71.1468V18.6302C71.1468 15.4657 69.8242 12.4595 67.5095 10.2444L60.8136 3.83636ZM124.88 0.355469V12.0639C124.88 13.0924 124.466 14.1208 123.64 14.8328L119.341 18.9466C118.597 19.6586 117.522 20.1333 116.448 20.1333H110.496C111.736 18.2346 112.48 16.0195 112.48 13.7253V0.355469H100.08V12.0639C100.08 13.0924 99.6666 14.1208 98.8399 14.8328L94.5413 18.9466C93.7973 19.6586 92.7226 20.1333 91.648 20.1333H87.68V0.355469H75.2801V32H93.384C96.6906 32 99.8319 30.7342 102.147 28.5191L106.28 24.5635V32H118.184C121.49 32 124.632 30.7342 126.946 28.5191L133.642 22.1111C135.957 19.8959 137.28 16.8897 137.28 13.7253V0.355469H124.88ZM0.880859 12.2222H9.14745V32H21.5473V12.2222H29.8139V0.355469H0.880859V12.2222Z"
      fill="#1325A9"
    />
  </svg>
)
