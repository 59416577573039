import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { gsap } from 'gsap'
import Img from 'gatsby-image'

import { H1 } from '../shared/text'
import { media } from '../utils/style-utils'

const Container = styled.div`
  width: 100%;
  display: grid;
  place-items: center;

  ${media.small`
    padding: 27px 16px 35px 16px;
  `}
  ${media.medium`
    padding: 41px 20px 41px 36px;
  `}
  ${media.large`
    padding: 61px 40px 81px 40px;
  `}
`

const StyledH1 = styled(H1)`
  visibility: hidden;
  display: inline-block;

  color: ${props => props.theme.colors.azure};

  & > * {
    vertical-align: middle;
  }

  ${media.large`
    max-width: 1268px;
    font-size: 80px;
    font-weight: 500;
    line-height: 125px;
  `}
`

const sway = keyframes`
  0% { transform: rotate(0); }
  25% { transform: rotate(-2deg); }
  50% { transform: rotate(0); }
  75% { transform: rotate(2deg); }
  100% { transform: rotate(0); }
`

const EggIcon = styled.div`
  animation: ${sway} 1.4s linear infinite;

  cursor: none;
  transition: 150ms ease all;
  display: inline-block;

  ${media.small`
    width: 45px;
  `}
  ${media.large`
    width: 105px;
    & > div {
      filter: grayscale(100%);
      transition: 150ms ease all;
    }
  `}

  &:hover > div {
    filter: grayscale(0%);
    transform: scale(1.05);
  }
`

const DesktopEgg = styled(EggIcon)`
  ${media.small`
    display: none;
  `}
  ${media.medium`
    display: inline-block;
  `}
`
const MobileEgg = styled(EggIcon)`
  ${media.small`
    display: inline-block;
  `}
  ${media.medium`
    display: none;
  `}
`

const float = keyframes`
  0% { transform: translate(0,0) }
  50% { transform: translate(0, -6px) }
  100% { transform: translate(0, 0) }
`

const LightbulbIcon = styled(EggIcon)`
  animation: ${float} 3s linear infinite;

  ${media.small`
    width: 31px;
  `}
  ${media.large`
    width: 77px;
  `}
`

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); }
`

const MarsIcon = styled(EggIcon)`
  animation: ${rotate} 13s linear infinite;

  ${media.small`
    width: 51px;
  `}
  ${media.large`
    width: 120px;
  `}
`

const query = graphql`
  query {
    egg: file(relativePath: { regex: "/homepage/egg.png/" }) {
      childImageSharp {
        fluid(maxWidth: 105) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lightbulb: file(relativePath: { regex: "/homepage/lightbulb.png/" }) {
      childImageSharp {
        fluid(maxWidth: 77) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mars: file(relativePath: { regex: "/homepage/mars.png/" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const GatsbyImage = ({ fluid, alt }) => (
  <Img
    fluid={fluid}
    alt={alt}
    style={{ height: '100%', width: '100%' }}
    imgStyle={{ objectFit: 'contain' }}
    className="above-the-fold__image"
  />
)

const AboveTheFold = () => {
  const { egg, lightbulb, mars } = useStaticQuery(query)

  useEffect(() => {
    const tl = gsap.timeline({
      defaults: { duration: 1.2, ease: 'power4.out' },
    })

    tl.to('.above-the-fold__copy', { autoAlpha: 1, duration: 0.1 })
    tl.from('.above-the-fold__copy', { y: -80 })
  }, [])

  return (
    <Container>
      <StyledH1 className="above-the-fold__copy">
        Innovation Department is a tech-empowered{' '}
        <DesktopEgg>
          <GatsbyImage fluid={egg.childImageSharp.fluid} alt="Egg icon" />
        </DesktopEgg>{' '}
        startup studio{' '}
        <MobileEgg>
          <GatsbyImage fluid={egg.childImageSharp.fluid} alt="Egg icon" />
        </MobileEgg>{' '}
        building{' '}
        <LightbulbIcon>
          <GatsbyImage
            fluid={lightbulb.childImageSharp.fluid}
            alt="Lightbulb icon"
          />
        </LightbulbIcon>{' '}
        the brands of the{' '}
        <MarsIcon>
          <GatsbyImage fluid={mars.childImageSharp.fluid} alt="Mars icon" />
        </MarsIcon>{' '}
        future
      </StyledH1>
    </Container>
  )
}

export default AboveTheFold
