import React, { useEffect } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import LinkArrow from '../shared/LinkArrow'
import { H2, P } from '../shared/text'
import { media } from '../utils/style-utils'

import EntrepreneurSVG from '../shared/image-components/press/EntrepreneurSVG'
import DigidaySVG from '../shared/image-components/press/DigidaySVG'
import AdweekSVG from '../shared/image-components/press/AdweekSVG'
import VentureBeatSVG from '../shared/image-components/press/VentureBeatSVG'
import FastCompanySVG from '../shared/image-components/press/FastCompanySVG'
import TnwSVG from '../shared/image-components/press/TnwSVG'

const Container = styled.div`
  position: relative;
  width: 100%;

  ${media.small`
    padding: 60px 44px 132px 44px;
  `}
  ${media.large`
    padding: 91px 100px 110px 100px;
  `}
`

const Header = styled.div`
  width: 100%;
  text-align: center;

  ${media.large`
    max-width: 1268px;
    margin: 0 auto;
  `}

  & > h2,
  > p {
    visibility: hidden;
    color: ${props => props.theme.colors.azure} !important;
  }

  & > h2 {
    margin-bottom: 20px;
  }
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  place-items: center;

  ${media.small`
    margin: 50px auto 0 auto;
    grid-gap: 40px 45px;
    grid-template-columns: 1fr 1fr;
  `}
  ${media.large`
    margin: 66px auto 0 auto;
    grid-gap: 60px 120px;
    max-width: 800px;
    grid-template-columns: 1fr 1fr 1fr;
  `}
`

const ImageWrapper = styled.a`
  height: 100%;
  max-height: 40px;
  width: 100%;
  max-width: 222px;

  ${media.large`
    min-height: ${props => props.minHeight};
  `}

  & svg {
    height: 100%;
    max-height: 40px;
    width: 100%;
    max-width: 222px;

    & path {
      fill: ${props => props.theme.colors.azure};
    }
  }

  &:hover svg {
    transition: 250ms ease all;
    transform: scale(1.03);

    & path {
      fill: ${props => props.theme.colors.healerite};
    }
  }
`

const LinkArrowWrapper = styled.div`
  position: absolute;
  color: ${props => props.theme.colors.azure} !important;

  ${media.small`
    bottom: 40px;
    right: 30px;
  `}
  ${media.large`
    top: 65px;
    bottom: auto;
    right: 10%;
  `}
`

const Press = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const tl = gsap.timeline({
      defaults: { opacity: 0, duration: 0.5, y: 20 },
      scrollTrigger: '.press__title',
    })

    tl.to('.press__title, .press__body, .press__read-more-link', {
      visibility: 'visible',
    })
    tl.from('.press__title', {})
    tl.from('.press__img', { duration: 1, stagger: 0.15, y: 0 }, '<')
    tl.from('.press__body', {}, '<0.15')
    tl.from('.press__read-more-link', { duration: 1.5, x: -20 }, '<')
  }, [])

  const images = [
    {
      component: EntrepreneurSVG,
      alt: 'Entrepreneur',
      url: 'https://www.entrepreneur.com/article/335748',
    },
    {
      component: DigidaySVG,
      alt: 'Digiday',
      url:
        'https://digiday.com/retail/amazon-using-two-accelerator-programs-get-brands-sell-platform/?utm_medium=email&utm_campaign=digidaydis&utm_source=daily&utm_content=190207',
    },
    {
      component: AdweekSVG,
      alt: 'Adweek',
      url:
        'https://www.advertisingweek360.com/out-with-the-old-in-with-the-rebrand/?utm_source=Direct',
      minHeight: 47,
    },
    {
      component: VentureBeatSVG,
      alt: 'VentureBeat',
      url:
        'https://venturebeat.com/2019/06/08/how-direct-to-consumer-brands-can-avoid-startup-purgatory/',
    },
    {
      component: FastCompanySVG,
      alt: 'Fast Company',
      url:
        'https://www.fastcompany.com/90370478/time-management-can-ruin-your-productivity-heres-how-to-prevent-that-from-happening',
    },
    {
      component: TnwSVG,
      alt: 'TNW',
      url:
        'https://thenextweb.com/podium/2019/06/10/corporate-innovation-isnt-dead-it-just-needs-an-outside-perspective/',
    },
  ]

  return (
    <Container>
      <LinkArrowWrapper className="press__read-more-link">
        <LinkArrow copy="read more" destination="/press" />
      </LinkArrowWrapper>

      <Header>
        <H2 className="press__title">Word on the street</H2>
        <P className="press__body">
          Okay, not the street. But, you know, the interweb.
        </P>
      </Header>

      <Grid>
        {images.map(image => {
          const SvgComponent = image.component
          return (
            <ImageWrapper
              href={image.url}
              target="_blank"
              rel="noopener noreferrer"
              minHeight={image.minHeight || 'none'}
              className="press__img"
              aria-label={image.alt}
              key={image.alt}
            >
              <SvgComponent />
            </ImageWrapper>
          )
        })}
      </Grid>
    </Container>
  )
}

export default Press
